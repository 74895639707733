import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PropTypes from 'prop-types';
import Rehype from 'rehype-react';

const renderAst = new Rehype({
  createElement: React.createElement
}).Compiler;

const Default = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title },
      htmlAst
    }
  } = data;

  return (
    <>
      <SEO title={title} />
      {renderAst(htmlAst)}
    </>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
        path
        tags
      }
    }
  }
`;

Default.propTypes = {
  data: PropTypes.object
};

export default Default;
